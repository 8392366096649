import axios from "axios";
import isEmpty from "lodash/isEmpty";
import type { Subscription } from "rxjs";
import { interval, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

import addressesMain from "./addresses.json";
import addressesLocal from "./addresses_local.json";
import addressesShasta from "./addresses_shasta.json";
import { btod, dtob, getPair } from "./tokens";

export enum Chain {
  MAIN = "0x2b6653dc",
  SHASTA = "0x94a9059e",
  LOCAL = "local",
}

export function getAddresses(network: Chain) {
  switch (network) {
    case Chain.LOCAL:
      return addressesLocal;
      break;
    case Chain.SHASTA:
      return addressesShasta;
      break;
    case Chain.MAIN:
    default:
      return addressesMain;
  }
}

export async function checkTxStatus(tx: string, intervalMs = 1000) {
  let subscription: Subscription;
  const stop$ = new Subject<void>();

  return new Promise((resolve, reject) => {
    subscription = interval(intervalMs)
      .pipe(
        takeUntil(stop$),
        switchMap(async () => {
          const { data } = await rawNodeRequest(
            `${window.tronWeb.fullNode.host}/wallet/gettransactioninfobyid`,
            { value: tx },
            "GET",
          );

          return data;
        }),
      )
      .subscribe({
        next: (transactionInfo) => {
          if (!isEmpty(transactionInfo)) {
            const result = transactionInfo.result;
            console.log({ transactionInfo });

            stop$.next(); // Emit to stop the observable
            stop$.complete(); // Complete the subject

            if (transactionInfo.receipt?.result === "SUCCESS") {
              resolve(transactionInfo);
            } else {
              resolve(transactionInfo);
            }
          }
        },
        error: (err) => {
          stop$.next(); // Emit to stop the observable
          stop$.complete(); // Complete the subject
          reject(err);
        },
      });
  });
}

export const contracts = (network: Chain) => {
  const addresses = getAddresses(network);

  return [
    {
      name: "Factory",
      address: addresses.Factory,
    },
    {
      name: "Router",
      address: addresses.Router,
    },
  ];
};

export async function rawNodeRequest(
  endpoint: string,
  params: any,
  method: "GET" | "POŜT" = "GET",
) {
  // @ts-ignore
  return axios[method.toLowerCase()](
    [endpoint],
    method === "GET" ? { params: { ...params } } : { data: { ...params } },
  );
}

const cachedContracts = new Map<string, any>();
export async function getContract(contractAddress: string) {
  if (!cachedContracts.has(contractAddress)) {
    const contract = await window.tronWeb.contract().at(contractAddress);
    cachedContracts.set(contractAddress, contract);
  }

  return cachedContracts.get(contractAddress);
}

export async function getBalanceForWalletToken(
  walletAddress: string,
  tokenAddress: string,
) {
  const accountHex = window.tronWeb.address.toHex(walletAddress);
  const contract = await getContract(tokenAddress);
  const decimals = await contract.decimals().call();
  let balance = await contract.balanceOf(accountHex).call();
  balance = window.tronWeb.toDecimal(balance._hex);
  const adjustedBalance = balance / Math.pow(10, decimals);

  return adjustedBalance;
}

export async function addLiquidity(
  contractAddress: string,
  tokenAAddress: string,
  tokenBAddress: string,
  amountAd: number,
  amountBd: number,
  minAmountAd: number,
  minAmountBd: number,
  deadline: number,
) {
  const contract = await getContract(contractAddress);
  const amounts = await Promise.all(
    (
      [
        [tokenAAddress, amountAd],
        [tokenBAddress, amountBd],
        [tokenAAddress, minAmountAd],
        [tokenBAddress, minAmountBd],
      ] as [string, number][]
    ).map((params) => dtob(params[0], params[1])),
  );

  try {
    const response = await contract
      .addLiquidity(
        tokenAAddress,
        tokenBAddress,
        amounts[0],
        amounts[1],
        amounts[2],
        amounts[3],
        window.tronWeb.defaultAddress.base58,
        deadline,
      )
      .send();

    return response;
  } catch (err) {
    throw "Unexpected error. Try different amounts.";
  }
}

export function quoteLiquidity(
  amountAd: number,
  reserveAd: number,
  reserveBd: number,
) {
  if (amountAd <= 0) throw new Error("SwapLibrary: INSUFFICIENT_AMOUNT");
  if (reserveAd <= 0 || reserveBd <= 0)
    throw new Error("SwapLibrary: INSUFFICIENT_LIQUIDITY");

  return (amountAd * reserveBd) / reserveAd;
}

export async function calcOtherLiquidityAmount(
  contractAddress: string,
  amount: number,
  tokenAddress: string,
  tokens: string[],
) {
  const pair = await getPair(contractAddress, tokens[0], tokens[1]);
  const [reserveAd, reserveBd] = await Promise.all(
    [
      [tokens[0], pair.reserveA],
      [tokens[1], pair.reserveB],
    ].map((params) => btod(params[0], params[1])),
  );

  if (tokenAddress === tokens[0]) {
    // Calculate amount of Token B based on amount of Token A
    return quoteLiquidity(amount, reserveAd, reserveBd);
  } else {
    // Calculate amount of Token A based on amount of Token B
    return quoteLiquidity(amount, reserveBd, reserveAd);
  }
}
