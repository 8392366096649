import type { Chain } from "./network";
import { getAddresses, getContract } from "./network";

export const tokens = (network: Chain) => {
  const addresses = getAddresses(network);

  return [
    {
      symbol: "TRX",
      name: "Tron",
      decimal: "6",
      url: "https://static.tronscan.org/production/logo/trx.png",
      address: addresses.TRX,
    },
    {
      symbol: "USDT",
      name: "Tether",
      decimal: "6",
      url: "https://coin.top/production/logo/usdtlogo.png",
      address: addresses.USDT,
    },
    {
      symbol: "SST",
      name: "SocialSwapToken",
      decimal: "8",
      url: "https://www.socialswap.io/coins/sst.png",
      address: addresses.SST,
    },
  ];
};

export async function btod(tokenAddress: string, amount: number) {
  const contract = await getContract(tokenAddress);
  const decimals = await contract.decimals().call();
  const adjustedAmount = amount / Math.pow(10, decimals);

  return adjustedAmount;
}

export async function dtob(tokenAddress: string, amount: number) {
  const contract = await getContract(tokenAddress);
  const decimals = await contract.decimals().call();

  return "0x" + (Math.floor(amount * 10 ** decimals) || 0).toString(16);
}

export async function allowance(tokenAddress: string, contractAddress: string) {
  const contract = await getContract(tokenAddress);
  const response = await contract
    .allowance(window.tronWeb.defaultAddress.base58, contractAddress)
    .call();

  return response ? window.tronWeb.toDecimal(response._hex) : false;
}

export async function approve(
  tokenAddress: string,
  contractAddress: string,
  amountd: number,
) {
  const contract = await getContract(tokenAddress);
  const response = await contract
    .approve(contractAddress, await dtob(tokenAddress, amountd))
    .send();

  return response;
}

export async function getPair(
  routerAddress: string,
  tokenAAddress: string,
  tokenBAddress: string,
) {
  const contract = await getContract(routerAddress);
  const response = await contract
    .getPair(window.tronWeb.defaultAddress.base58, tokenAAddress, tokenBAddress)
    .call();

  return response;
}

export async function calcOtherSwapAmount(
  contractAddress: string,
  amountd: number,
  path: string[],
) {
  const [tokenA, tokenB] = path;
  const contract = await getContract(contractAddress);

  try {
    const response = await contract
      .getAmountsOut(await dtob(tokenA, amountd), [tokenA, tokenB])
      .call();
    const [amountA, amountB] = response.amounts;
    const [amountAd, amountBd] = await Promise.all([
      btod(tokenA, amountA),
      btod(tokenB, amountB),
    ]);

    return [amountAd, amountBd];
  } catch (err) {
    throw err;
  }
}

export async function swap(
  contractAddress: string,
  tokenFromAddress: string,
  tokenToAddress: string,
  amountFromd: number,
  amountTod: number,
  minAmountTod: number,
  deadline: number,
) {
  const contract = await getContract(contractAddress);
  const [amountFromb, amountTob, minAmountTob] = await Promise.all(
    (
      [
        [tokenFromAddress, amountFromd],
        [tokenToAddress, amountTod],
        [tokenToAddress, minAmountTod],
      ] as [string, number][]
    ).map((params) => dtob(params[0], params[1])),
  );

  try {
    const response = await contract
      .swapExactTokensForTokens(
        amountFromb,
        minAmountTob,
        [tokenFromAddress, tokenToAddress],
        window.tronWeb.defaultAddress.base58,
        deadline,
      )
      .send();

    return response;
  } catch (err) {
    throw "Unexpected error. Try different amounts";
  }
}
