import { create } from "zustand";

import {
  addLiquidity,
  Chain,
  checkTxStatus,
  contracts,
} from "~/utils/web3/network";
import { allowance, approve, btod, swap } from "~/utils/web3/tokens";

export interface Web3Store {
  slippage: number;
  deadline: number;
  network: Chain;
  getRouterAddress: () => string | undefined;
  getFactoryAddress: () => string | undefined;
  setNetwork: (chainId: Chain) => void;
  addLiquidity: (
    tokenA: string,
    tokenB: string,
    amountAd: number,
    amountBd: number,
  ) => Promise<void | string>;
  swap: (
    tokenFrom: string,
    tokenTo: string,
    amountFromd: number,
    amountTod: number,
  ) => Promise<void | string>;
}

const useWeb3Store = create<Web3Store>((set, get) => ({
  network: Chain.MAIN,
  slippage: 0,
  deadline: 3,
  getRouterAddress: () =>
    contracts(get().network).find((c) => c.name === "Router")?.address,
  getFactoryAddress: () =>
    contracts(get().network).find((c) => c.name === "Factory")?.address,
  setNetwork: (chainId: Chain) =>
    set((state) => ({ ...state, network: chainId })),
  addLiquidity: async (tokenA, tokenB, amountAd, amountBd) => {
    const deadline = parseInt(String(Date.now() / 1000)) + get().deadline * 60;
    const slippage = get().slippage;
    const minAmountAd = amountAd - (amountAd / 100) * slippage;
    const minAmountBd = amountBd - (amountBd / 100) * slippage;
    const routerAddress = get().getRouterAddress();
    let approvedATxId,
      approvedBTxId = null;

    const [allowanceA, allowanceB] = await Promise.all([
      allowance(tokenA, routerAddress as string),
      allowance(tokenB, routerAddress as string),
    ]);
    const [allowanceAb, allowanceBb] = await Promise.all(
      [
        [tokenA, allowanceA],
        [tokenB, allowanceB],
      ].map((params) => btod(params[0], params[1])),
    );

    if (!allowanceA || allowanceAb < amountAd) {
      approvedATxId = await approve(tokenA, routerAddress as string, amountAd);
    }

    if (!allowanceB || allowanceBb < amountBd) {
      approvedBTxId = await approve(tokenB, routerAddress as string, amountBd);
    }

    const [approvedA, approvedB] = await Promise.all(
      [approvedATxId, approvedBTxId].map(
        (approvalTxId) => (approvalTxId && checkTxStatus(approvalTxId)) || true,
      ),
    );

    console.log({ approvedA, approvedB });

    if (approvedA && approvedB) {
      return addLiquidity(
        routerAddress as string,
        tokenA,
        tokenB,
        amountAd,
        amountBd,
        minAmountAd,
        minAmountBd,
        deadline,
      );
    } else {
      throw "Not enough funds to add";
    }
  },
  swap: async (tokenFrom, tokenTo, amountFromd, amountTod) => {
    const deadline = parseInt(String(Date.now() / 1000)) + get().deadline * 60;
    const slippage = get().slippage;
    const minAmountTod = amountTod - (amountTod / 100) * slippage;
    const routerAddress = get().getRouterAddress();
    let approvedATxId,
      approvedBTxId = null;

    const [allowanceA] = await Promise.all([
      allowance(tokenFrom, routerAddress as string),
    ]);
    const [allowanceAb] = await Promise.all(
      [[tokenFrom, allowanceA]].map((params) => btod(params[0], params[1])),
    );

    if (!allowanceA || allowanceAb < amountFromd) {
      approvedATxId = await approve(
        tokenFrom,
        routerAddress as string,
        amountFromd,
      );
    }

    const [approvedA] = await Promise.all(
      [approvedATxId].map(
        (approvalTxId) => (approvalTxId && checkTxStatus(approvalTxId)) || true,
      ),
    );

    console.log({ approvedA });

    if (approvedA) {
      return swap(
        routerAddress as string,
        tokenFrom,
        tokenTo,
        amountFromd,
        amountTod,
        minAmountTod,
        deadline,
      );
    } else {
      throw "Not enough funds to swap";
    }
  },
}));

export default useWeb3Store;
